import TransitionLink from "gatsby-plugin-transition-link"
import React from "react"
import { Controller, Scene } from "react-scrollmagic"

const MenuHeader = () => (
  <Controller>
    <Scene
      pin={true}
      pinSettings={{ pushFollowers: false }}
      offset={-85}
      duration={200}
      triggerHook={0}
    >
      <header className="row">
        <nav id="main-nav" className="col-xs-12 col-sm-4 col-md-4">
          <ul>
            <li>
              <TransitionLink
                to="/"
                className="menu-item menu-item-home"
                activeClassName="current_page_item"
                entry={{ length: 0.75 }}
                exit={{ length: 0.5 }}
              >
                <span className="menu-line"></span>
                Rivic Visuals
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/leistungen"
                className="menu-item"
                activeClassName="current_page_item"
                entry={{ length: 0.75 }}
                exit={{ length: 0.5 }}
              >
                <span className="menu-line"></span>
                Leistungen
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/portfolio"
                className="menu-item"
                activeClassName="current_page_item"
                partiallyActive={true}
                entry={{ length: 0.75 }}
                exit={{ length: 0.5 }}
              >
                <span className="menu-line"></span>
                Portfolio
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/contact"
                className="menu-item"
                activeClassName="current_page_item"
                entry={{ length: 0.75 }}
                exit={{ length: 0.5 }}
              >
                <span className="menu-line"></span>
                Kontakt
              </TransitionLink>
            </li>
          </ul>
        </nav>
      </header>
    </Scene>
  </Controller>
)

export default MenuHeader
