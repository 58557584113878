import React from "react"
import PropTypes from "prop-types"

import MenuHeader from "./menuheader"
import Footer from "./footer"

const MenuLayout = ({ children, customClasses }) => {
  return (
    <div className={`page ${customClasses || ""}`}>
      <div className="container-fluid">
        <MenuHeader />
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  )
}

MenuLayout.propTypes = {
  children: PropTypes.node.isRequired,
  customClasses: PropTypes.string,
}

export default MenuLayout
