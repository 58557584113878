import React from "react"

import SEO from "../components/seo"
import MenuLayout from "../components/menulayout"

const Impressum = () => (
  <MenuLayout>
    <SEO title="Impressum"></SEO>
    <section className="row">
      <h1>Impressum</h1>
    </section>

    <section className="row">
      <article className="col-xs-12 col-sm-6 col-md-6">
        <h3>
          Verantwortlich <br />
          für den Inhalt der Webseite
        </h3>
        <p>
          Luka Rivic
          <br />
          Rettichgasse 7/4/6
          <br />
          1140 Wien, Österreich
          <br />
          <br />
          +43 660 575 46 45
          <br />
          luka@rivic.at
          <br />
          <br />
          Rechtsform: Einzelunternehmen
          <br />
          UID-Nummer: ATU69040469
          <br />
          Geschäftsführung: Luka Rivic
          <br />
          <br />
          <strong>Bankdaten</strong>
          <br />
          Raiffeisenlandesbank NÖ-Wien AG
          <br />
          BIC: RLNWATWW
          <br />
          IBAN: AT74 3200 0000 0484 1573
        </p>
      </article>
      <article className="col-xs-12">
        <h3>Haftungsausschluss</h3>
        <p></p>
        <p>
          <strong>1. Inhalt des Onlineangebotes</strong>
          <br />
          Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
          Vollständigkeit oder Qualität der bereitgestellten Informationen.
          Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
          oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung
          der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
          unvollständiger Informationen verursacht wurden, sind grundsätzlich
          ausgeschlossen, sofern seitens des Autors kein nachweislich
          vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
          <br />
          Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es
          sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>
        <p>
          <strong>2. Verweise und Links</strong>
          <br />
          Bei direkten oder indirekten Verweisen auf fremde Webseiten
          (Hyperlinks), die außerhalb des Verantwortungsbereiches des Autors
          liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in
          Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es
          ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern.
          <br />
          Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der
          Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten
          erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die
          Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat
          der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit
          ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten,
          die nach der Linksetzung verändert wurden. Diese Feststellung gilt für
          alle innerhalb des eigenen Internetangebotes gesetzten Links und
          Verweise sowie für Fremdeinträge in vom Autor eingerichteten
          Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und
          in allen anderen Formen von Datenbanken, auf deren Inhalt externe
          Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder
          unvollständige Inhalte und insbesondere für Schäden, die aus der
          Nutzung oder Nichtnutzung solcherart dargebotener Informationen
          entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen
          wurde, nicht derjenige, der über Links auf die jeweilige
          Veröffentlichung lediglich verweist.
        </p>
        <p>
          <strong>3. Urheber- und Kennzeichenrecht</strong>
          <br />
          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
          verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu
          beachten, von ihm selbst erstellte Grafiken, Tondokumente,
          Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
          Tondokumente, Videosequenzen und Texte zurückzugreifen.
          <br />
          Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte
          geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
          Bestimmungen des jeweils gültigen Kennzeichenrechts und den
          Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund
          der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen
          nicht durch Rechte Dritter geschützt sind!
          <br />
          Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte
          bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder
          Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in
          anderen elektronischen oder gedruckten Publikationen ist ohne
          ausdrückliche Zustimmung des Autors nicht gestattet.
        </p>
        <p>
          <strong>4. Datenschutz</strong>
          <br />
          Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe
          persönlicher oder geschäftlicher Daten (Emailadressen, Namen,
          Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens
          des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme
          und Bezahlung aller angebotenen Dienste ist – soweit technisch möglich
          und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe
          anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der
          im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten
          Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie
          Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderten Informationen ist nicht gestattet. Rechtliche Schritte
          gegen die Versender von sogenannten Spam-Mails bei Verstössen gegen
          dieses Verbot sind ausdrücklich vorbehalten.
        </p>
        <p>
          <strong>5. Rechtswirksamkeit dieses Haftungsausschlusses</strong>
          <br />
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
          betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
          oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
          nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben
          die übr /igen Teile des Dokumentes in ihrem Inhalt und ihrer
          Gültigkeit davon unberührt.
        </p>
      </article>
    </section>
  </MenuLayout>
)

export default Impressum
